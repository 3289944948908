<template>
  <v-btn
    link
    text
    class="main-nav-btn mx-1"
    small
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <!-- <v-icon class="mr-1" small>{{ icon }}</v-icon> -->
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseMainNavBtn',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>
