<template>
  <v-menu
    v-model="notifications.display"
    :close-on-content-click="false"
    :nudge-width="200"
    origin="top left"
    transition="scale-transition"
    top
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        class="mx-1"
        small
        v-bind="attrs"
        v-on="on"
        @click="reloadNotifications"
      >
        <v-badge
          v-if="$store.state.admin && $store.state.admin.unread_notifications"
          :content="$store.state.admin.unread_notifications"
          :value="$store.state.admin && ($store.state.admin.unread_notifications > 0)"
          color="red"
          overlap
        >
          <v-icon
            small
          >mdi-bell</v-icon>
        </v-badge>
        <v-icon
          v-else
          small
        >mdi-bell-outline</v-icon>
      </v-btn>
    </template>

    <div
      style="max-height: 300px;"
    >
      <v-card
        tile
        class="default-content--text"
        color="default-panel"
      >
        <v-list
          class="default-content--text"
          color="default-panel"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Notifications</v-list-item-title>
              <v-list-item-subtitle>{{ this.$store.state.admin.email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                v-if="$store.state.admin"
                icon
                class="mr-4"
              >
                <v-badge
                  :content="$store.state.admin.unread_notifications"
                  :value="$store.state.admin && ($store.state.admin.unread_notifications > 0)"
                  color="red"
                  overlap
                >
                  <v-icon
                    v-if="$store.state.admin.unread_notifications"
                  >mdi-bell</v-icon>
                  <v-icon
                    v-else
                  >mdi-bell-outline</v-icon>
                </v-badge>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-timeline
          dense
          reverse
          style="max-width: 400px;"
        >
          <v-timeline-item
            v-for="notification in this.notifications.data"
            :key="notification.id"
            small
            :color="getNotificationColor(notification)"
          >
            <v-card
              v-if="notification.type == 'announcement'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Announcement</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>{{ notification.data.message }}</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_added'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                has been added to {{ notification.data.account.name }}.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_removed'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                has been removed from {{ notification.data.account.name }}.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_updated'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                has been updated.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_credentials_required'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                credentials are invalid.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_captcha_detected'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                captcha detected.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_pin_detected'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                PIN required.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_pin_submitted'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                PIN submitted.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_status_changed'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                status changed to {{ notification.data.status }}.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_setup_finished'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                setup finished successfully.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'linkedin_setup_failed'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>LinkedIn Account</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                <base-linkedin-account
                  v-model="notification.data.linkedin"
                ></base-linkedin-account>
                setup failed: {{ notification.data.error }}</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'task_added'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Task <strong>{{ notification.data.task.label }}</strong> has been created.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'task_updated'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Task <strong>{{ notification.data.task.label }}</strong> has been updated.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'task_removed'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Task <strong>{{ notification.data.task.label }}</strong> has been deleted.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'task_assigned'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Task <strong>{{ notification.data.task.label }}</strong> has been assigned to <strong>{{ notification.data.user.name }}</strong>.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'task_contact_added'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Contact <strong>{{ notification.data.contact.first_name }} {{ notification.data.contact.last_name }}</strong> has been added to <strong>{{ notification.data.task.label }}</strong>.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'task_contact_removed'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Contact <strong>{{ notification.data.contact.first_name }} {{ notification.data.contact.last_name }}</strong> has been removed from <strong>{{ notification.data.task.label }}</strong>.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'task_company_added'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Company <strong>{{ notification.data.company.name }}</strong> has been added to <strong>{{ notification.data.task.label }}</strong>.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'task_company_removed'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Company <strong>{{ notification.data.company.name }}</strong> has been removed from <strong>{{ notification.data.task.label }}</strong>.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'todo_list_added'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Todo List <strong>{{ notification.data.todo_list.label }}</strong> has been added to <strong>{{ notification.data.task.label }}</strong>.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'todo_list_updated'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Task <strong>{{ notification.data.task.label }}</strong> has been updated.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'todo_list_removed'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Todo List <strong>{{ notification.data.todo_list.label }}</strong> has been removed from <strong>{{ notification.data.task.label }}</strong>.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'todo_added'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                New todo item <strong>{{ notification.data.todo.label }}</strong> has been added to <strong>{{ notification.data.todo_list.label }}</strong> of the <strong>{{ notification.data.task.label }}</strong>.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'todo_updated'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Task <strong>{{ notification.data.task.label }}</strong> has been updated.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'todo_removed'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Todo <strong>{{ notification.data.todo.label }}</strong> has been removed from <strong>{{ notification.data.todo_list.label }}</strong> of <strong>{{ notification.data.task.label }}</strong>.</div>
            </v-card>
            <v-card
              v-else-if="notification.type == 'todo_completed'"
              class="pa-3 ml-4"
              :color="getNotificationColor(notification)"
              dark
            >
              <div>
                <h5>
                  <span>Task</span>
                </h5>
                <div class="text-subtitle-2">{{ notification.created_at }}</div>
              </div>
              <div>
                Completed <strong>{{ notification.data.todo.label }}</strong> in <strong>{{ notification.data.todo_list.label }}</strong> of <strong>{{ notification.data.task.label }}</strong>.</div>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card>
      <div
        v-intersect="notificationsInfiniteScrolling"
      ></div>
    </div>
  </v-menu>
</template>

<script>
import NotificationsApi from "@/api/NotificationsApi";
import NotificationSfx from '@/assets/notification.wav';

export default {
  name: 'MainNavNotifications',

  data: () => ({
    notifications: {
      display: false,
      data: [],
      page: 0,
      total: 0,
      loading: false,
    },
    interval: null,
    lastUnreadCount: 0,
  }),

  mounted() {
    this.interval = setInterval(() => {
        if (this.$store.state.admin && (this.$store.state.admin.unread_notifications > this.lastUnreadCount)) {
          this.lastUnreadCount = this.$store.state.admin.unread_notifications;
          this.playSoundAlert();
        }
    }, 5000);
  },

  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },

  methods: {

    loadData: function () {
      this.$store.dispatch('loadAdmin');
    },

    loadNotifications: function () {
      if (this.notifications.loading) return;
      if ((this.notifications.page > 0) && ((this.notifications.total / 5) < this.notifications.page)) return;
      this.notifications.loading = true;
      this.notifications.page += 1;
      var page = this.notifications.page;
      NotificationsApi.getNotifications(page, 5).then(response => {
        if (page == 1) {
          this.notifications.data = [];
          this.lastUnreadCount = 0;
        }
        this.notifications.data = this.notifications.data.concat(response.data.data);
        this.notifications.total = response.data.meta.total;
        this.notifications.loading = false;
        this.loadData();
      });
    },

    reloadNotifications: function () {
      if (this.notifications.loading) return;
      this.notifications.page = 0;
      this.notifications.total = 0;
      this.notifications.data = [];
      this.loadNotifications();
    },

    notificationsInfiniteScrolling: function () {
      if (this.notifications.loading) return;
      setTimeout(() => {
        this.loadNotifications();
      }, 500);
    },

    getNotificationColor: function (notification) {
      if (notification.type == 'announcement') {
        return 'red';
      } else if (notification.type == 'linkedin_credentials_required') {
        return 'warning';
      } else if (notification.type == 'linkedin_captcha_detected') {
        return 'warning';
      } else if (notification.type == 'linkedin_pin_detected') {
        return 'warning';
      } else if (notification.type == 'linkedin_pin_submitted') {
        return 'primary';
      } else if (notification.type.startsWith('linkedin')) {
        return 'blue';
      } else if ((notification.type.startsWith('task')) ||
                (notification.type.startsWith('todo'))) {
        return 'teal';
      }
    },

    playSoundAlert: function () {
      (async () => {
        const audio = new Audio(NotificationSfx);
        audio.play();
      })();
    },

  },
};
</script>
