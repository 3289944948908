<template>
      <v-btn
        class="px-2"
        :elevation="elevation"
        :color="color"
        :dark="!$store.state.isDarkMode"
        :outlined="$store.state.isDarkMode"
        v-bind="{ ...$attrs }"
        v-on="{ ...$listeners }"
      >
        <v-icon
          v-if="icon"
          :x-large="iconSize === 'x-large'"
          :large="iconSize === 'large'"
          :small="iconSize === 'small'"
          :x-small="iconSize === 'x-small'"
          class="mr-1"
        >{{ icon }}</v-icon>
        <span>{{ label }}</span>
      </v-btn>
</template>

<script>
export default {
  name: 'BaseViewBtn',

  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary'
    },
    iconSize: {
      type: String,
      default: 'small',
    },
    elevation: {
      type: [Number, String],
      default: 1,
    },
  },
};
</script>
