<template>
  <base-picture-avatar
    :src="src"
    :src-default="require(`@/assets/person.png`)"
    :width="width"
    :height="height"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  ></base-picture-avatar>
</template>

<script>
export default {
  name: 'BasePictureContactAvatar',

  inheritAttrs: false,

  props: {
    src: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: 40,
    },
    height: {
      type: [String, Number],
      default: 40,
    },
  },
};
</script>
