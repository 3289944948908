<template>
  <v-chip
    label
    color="blue darken-3"
    dark
    class="px-1"
    :small="dense"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <base-picture-company-avatar
      v-if="value && value.logo"
      :src="(value.logo) ? value.logo : ''"
      class="mr-1"
      :width="(dense) ? 16 : 22"
      :height="(dense) ? 16 : 22"
    ></base-picture-company-avatar>
    <span
      v-if="value"
    >{{ (value.name.length > maxLength) ? value.name.substring(0, (maxLength - 2)) + "..." : value.name }}</span>
    <v-icon
      v-if="value"
      class="ml-1"
      :small="dense"
    >mdi-linkedin</v-icon>
  </v-chip>
</template>

<script>
export default {
  name: 'BaseLinkedinCompany',

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    maxLength: function () {
      return (this.narrow) ? 20 : 25;
    },
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
};
</script>
