<template>
  <v-tooltip
    bottom
  >
    <template v-slot:activator="tooltip">
      <v-btn
        icon
        :width="width"
        :height="height"
        :elevation="elevation"
        :class="btnClasses"
        v-bind="{ ...$attrs, ...tooltip.attrs }"
        v-on="{ ...$listeners, ...tooltip.on }"
      >
        <v-icon
          small
          :x-small="small || xsmall"
        >mdi-help-box</v-icon>
      </v-btn>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'BaseInfoIcon',

  props: {
    label: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    btnClasses: function () {
      var color = (this.$store.state.isDarkMode) ? '' : 'white';
      var classes = color.split(' ');
      if (!this.xsmall) {
        classes.push('ma-1');
      }
      return classes;
    },
    width: function () {
      if (this.xsmall) {
        return 12; //20;
      } else if (this.small) {
        return 20; //28;
      }
      return 28; //36;
    },
    height: function () {
      if (this.xsmall) {
        return 12; //20;
      } else if (this.small) {
        return 20; //28;
      }
      return 28; //36;
    },
  },
};
</script>
