import Api from "@/api/Api";

export default {
  login(data) {
    return Api().post("/login", data);
  },

  logout() {
    return Api().post("/logout");
  },

  auth() {
    return Api().get("/admin");
  }
};
