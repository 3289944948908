<template>
  <div
    class="d-inline-block"
  >
    <base-dialog-fullscreen
      :value="show"
      @input="($event) ? $emit('show', show) : $emit('show', null)"
      :title="title"
    >
      <template v-slot:prepend>
        <slot name="prepend"></slot>
      </template>
      <template>
        <v-container
          fluid
          class="px-2 px-sm-16 pt-8"
        >
          <v-row
            dense
            no-gutters
          >
            <v-col
              cols="12"
              md="2"
              class="mb-4 mb-md-0"
            >
              <v-card
                class="rounded-r-0 mt-1 default-content--text"
                color="default-panel"
              >
                <v-text-field
                  v-model="dataSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  @keyup="loadData()"
                  @click:clear="dataSearch = ''; loadData()"
                  single-line
                  hide-details
                  clearable
                  class="px-3"
                ></v-text-field>
                <div
                  style="position: relative;"
                >
                  <v-card-actions
                    class="float-right pa-0"
                    style="position: absolute; top: 0; right: 0;"
                  >
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          small
                          tile
                          dark
                          elevation="1"
                          class="ma-2 blue"
                          @click="newData"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>New {{ singular }}</span>
                    </v-tooltip>
                  </v-card-actions>
                </div>
                <base-data-table-remote
                  ref="dataTable"
                  :headers="dataTableHeaders"
                  :get-data="getListing"
                  loading-text="Loading..."
                  :no-data-text="'No ' + plural + ' found.'"
                  :search="dataSearch"
                  :footer-props="{
                    'show-first-last-page': true,
                    'disable-items-per-page': true,
                    'items-per-page-text': '',
                    'items-per-page-options': [],
                    'items-per-page-all-text': '',
                  }"
                  @click:row="selectData"
                >
                  <template v-slot:item.actions="props">
                    <div
                      class="text-no-wrap"
                    >
                      <base-data-table-btn
                        :label="'Remove ' + singular"
                        icon="mdi-delete"
                        icon-color="red"
                        @click.stop="confirmRemoveData(props.item.id)"
                      ></base-data-table-btn>
                      <base-data-table-btn
                        :label="'Edit ' + singular"
                        icon="mdi-pencil"
                        icon-color="blue"
                        @click.stop="editData(props.item)"
                      ></base-data-table-btn>
                    </div>
                  </template>
                </base-data-table-remote>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="10"
            >
              <v-card
                v-if="value"
                class="rounded-l-0"
                color="background"
              >
                <v-card-actions
                  class="float-right"
                  style="position: absolute; top: 0; right: 0;"
                >
                  <v-tooltip
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        tile
                        elevation="1"
                        class="ma-2 background"
                        @click="resetData"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-cancel</v-icon>
                      </v-btn>
                    </template>
                    <span>Cancel</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="value.id"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        tile
                        elevation="1"
                        class="ma-2 background"
                        @click="confirmRemoveData(value.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small color="red">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Remove {{ singular }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        tile
                        elevation="1"
                        class="ma-2 background"
                        @click="saveData()"
                        :disabled="(!allowCreate && (value && !value.id))"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small color="blue">mdi-content-save</v-icon>
                      </v-btn>
                    </template>
                    <span>Save {{ singular }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        tile
                        elevation="1"
                        class="ma-2 background"
                        @click="selectData()"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small color="green">{{ selectIcon }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Select {{ singular }}</span>
                  </v-tooltip>
                  <slot name="actions"></slot>
                </v-card-actions>
                <v-card-title
                  class="headline"
                  style="white-space: pre-wrap;"
                >Edit {{ singular }}: <strong>{{ (value.name) ? (value.name) : ('New ' + singular) }}</strong></v-card-title>
                <v-divider></v-divider>
                <slot></slot>
              </v-card>
              <v-card
                v-if="!value"
                class="rounded-l-0"
                color="background"
              >
                <v-card-title
                  class="headline"
                >Select a {{ singular }} or add a new one</v-card-title>
                <v-divider></v-divider>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <div
                      class="mx-5 my-10 grey--text"
                    >
                      No {{ plural }} selected.
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </base-dialog-fullscreen>
    <base-dialog-delete-confirmation
      v-model="removeId"
      :prompt="'Do you really want to remove this ' + singular + '?'"
      @removed="deleteData()"
    ></base-dialog-delete-confirmation>
  </div>
</template>

<script>
export default {
  name: 'BaseDialogFullscreenEditor',

  inheritAttrs: false,

  props: {
    value: {},
    show: {},
    title: {
      type: String,
      default: "",
    },
    singular: {
      type: String,
      default: "item",
    },
    plural: {
      type: String,
      default: "data",
    },
    selectIcon: {
      type: String,
      default: "mdi-check",
    },
    allowCreate: {
      type: Boolean,
      default: true,
    },
    dataTableHeaders: {
      type: Array,
      default: null,
    },
    get: {
      type: Function,
      default: null,
    },
    new: {
      type: Function,
      default: null,
    },
    save: {
      type: Function,
      default: null,
    },
    remove: {
      type: Function,
      default: null,
    },
    select: {
      type: Function,
      default: null,
    },
    reset: {
      type: Function,
      default: null,
    },
  },

  data: () => ({
    dataSearch: '',
    removeId: null,
  }),

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },

  created() {
    this.loadData();
  },

  methods: {

    getListing: function (page, itemsPerPage, sortBy, sortDesc) {
      return this.get(page, itemsPerPage, sortBy, sortDesc, this.dataSearch);
    },

    loadData: function () {
      if (this.$refs.dataTable) {
        this.$refs.dataTable.loadData();
      }
    },

    newData: function () {
      this.new();
    },

    saveData: function () {
      this.save(this.value)
      .then(() => {
        this.loadData();
      });
    },

    deleteData: function () {
      this.remove(this.removeId)
      .then(() => {
        if (this.value && (this.value.id == this.removeId)) {
          this.$emit('input', null);
        }
        this.removeId = null;
        this.loadData();
      });
    },

    confirmRemoveData: function (itemId) {
      this.removeId = itemId;
    },

    editData: function (item) {
      this.$emit('input', item);
    },

    selectData: function (item) {
      if (item) {
        this.$emit('input', item);
        this.select(item);
      } else {
        this.select(this.value);
      }
      this.$emit('show', null);
    },

    resetData: function () {
      this.$emit('input', null);
      this.reset();
    },

  },
};
</script>
