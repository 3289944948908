<template>
  <v-avatar
    :width="width"
    :min-width="width"
    :height="height"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <v-img
      :src="src || srcDefault"
      :lazy-src="srcDefault"
      :max-width="width"
      :max-height="height"
      contain
    ></v-img>
  </v-avatar>
</template>

<script>
export default {
  name: 'BasePictureAvatar',

  inheritAttrs: false,

  props: {
    src: {
      type: String,
      default: '',
    },
    srcDefault: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: '',
    },
    height: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>
