<template>
  <v-expansion-panels
    v-if="(preview && (preview != value)) || (value.length > 50)"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
    accordion
    flat
  >
    <v-expansion-panel>
      <v-expansion-panel-header
        class="pa-0"
        style="align-items: flex-start; min-height: 24px;"
      >
        <v-list-item-title
          style="align-self: flex-start;"
        >
          {{ (preview) ? preview : value }}
        </v-list-item-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        style="white-space: pre-wrap;"
      >
        {{ value }}
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-list-item-title
    v-else
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    {{ value }}
  </v-list-item-title>
</template>

<script>
export default {
  name: 'BaseSidebarViewerPanelValue',

  inheritAttrs: false,



  props: {
    value: {
      type: String,
      default: '',
    },
    preview: {
      type: String,
      default: '',
    },
  },
};
</script>
