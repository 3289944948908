<template>
  <base-sidebar
    :value="value"
    @input="$emit('input', $event)"
    fixed
    right
    temporary
    :width="width"
    class="pa-2"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <v-card-title
      class="headline"
    >{{ title }}</v-card-title>

    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>

    <v-divider class="my-2"></v-divider>

    <v-container
      class="pa-0"
    >
      <slot />
    </v-container>

    <v-divider class="my-2"></v-divider>

    <v-card-actions>
      <base-sidebar-btn
        v-if="(create != null)"
        @click="create()"
        :label="createLabel"
        :icon="createIcon"
        btn-class="mr-4 blue"
        icon-color="white"
      ></base-sidebar-btn>
      <base-sidebar-btn
        v-else-if="(save != null)"
        @click="save()"
        :label="saveLabel"
        :icon="saveIcon"
        btn-class="mr-4 blue"
        icon-color="white"
      ></base-sidebar-btn>
      <base-sidebar-btn
        @click="$emit('input', false)"
        icon="mdi-close"
        btn-class="grey lighten-5"
        icon-color="grey"
      ></base-sidebar-btn>
      <slot name="actions" />
    </v-card-actions>
  </base-sidebar>
</template>

<script>
export default {
  name: 'BaseSidebarObjectEditor',

  inheritAttrs: false,

  props: {
    value: {},
    width: {
      type: [Number, String],
      default: 350,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    create: {
      type: Function,
      default: null,
    },
    createLabel: {
      type: String,
      default: 'Create',
    },
    createIcon: {
      type: String,
      default: 'mdi-plus',
    },
    save: {
      type: Function,
      default: null,
    },
    saveLabel: {
      type: String,
      default: 'Save',
    },
    saveIcon: {
      type: String,
      default: 'mdi-content-save',
    },
  },

};
</script>
