<template>
  <main-nav-v-2
    @logout="logout()"
  ></main-nav-v-2>
</template>

<script>
//import MainNavV1 from '@/components/layout/MainNavV1.vue';
import MainNavV2 from '@/components/layout/MainNavV2.vue';

export default {
  name: 'MainNav',

  components: {
    //MainNavV1,
    MainNavV2,
  },

  data() {
    return {
      //
    };
  },

  methods: {

    logout: function () {
      this.$emit('logout');
    },

  },
};
</script>
