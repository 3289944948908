export function displayDate(date, onlyRecent = false) {
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  var seconds = Math.floor((new Date() - date) / 1000);
  if (seconds <= 0) {
    return 'just now';
  }

  if (onlyRecent && Math.floor(seconds / 86400)) {
    // when onlyRecent is true, display relative time only for dates that
    // occured less than a day ago. Otherwise, return full date (without time).
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + " year" + ((interval > 1) ? 's ago' : '');
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " month" + ((interval > 1) ? 's ago' : '');
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " day" + ((interval > 1) ? 's ago' : '');
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hour" + ((interval > 1) ? 's ago' : '');
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minute" + ((interval > 1) ? 's ago' : '');
  }

  interval = Math.floor(seconds);
  return interval + " second" + ((interval > 1) ? 's ago' : '');
}
