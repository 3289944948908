<template>
  <v-tooltip
    v-if="label"
    bottom
  >
    <template v-slot:activator="tooltip">
      <v-btn
        icon
        small
        tile
        :class="btnClass"
        v-bind="{ ...$attrs, ...tooltip.attrs }"
        v-on="{ ...$listeners, ...tooltip.on }"
      >
        <v-icon
          small
          :color="iconColor"
          :class="iconClass"
        >{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
  <v-btn
    v-else
    icon
    small
    tile
    :class="btnClass"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <v-icon
      small
      :color="iconColor"
      :class="iconClass"
    >{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseDataTableBtn',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    btnClass: {
      type: String,
      default: 'background',
    },
    iconColor: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
};
</script>
