import Vue from 'vue';
import Vuex from 'vuex';
import Vuetify from '@/plugins/vuetify';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
import AuthApi from "@/api/AuthApi";
import SettingsApi from "@/api/SettingsApi";

// Use Secure (encrypted) Local Storage for storing data in the browser
const ls = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
  encryptionSecret: process.env.VUE_APP_LS_ENCRYPTION_SECRET,
});

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "webwalker",
      storage: {
        getItem: (key) => {
          try {
            return ls.get(key)
          }
          catch (err) {
            ls.clear()
            return ls.get(key)
          }
        },
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    })
  ],
  state: {
    token: false, // Core API token
    admin: false, // Current admin details
    isDarkMode: false, // Dark\Light theme
    reverseMessageOrder: false, // Reverse message order in conversations
    isMaintenance: false, // Maintenance mode
    errors: [], // List of errors
  },
  getters: {
    authenticated(state) {
      // Consider authenticated if has token
      if (state.token) {
        return true;
      }
      return false;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setAdmin(state, admin) {
      state.admin = admin;
    },
    setDarkMode(state, isDarkMode) {
      state.isDarkMode = isDarkMode;
    },
    setReverseMessageOrder(state, reverseMessageOrder) {
      state.reverseMessageOrder = reverseMessageOrder;
    },
    setMaintenance(state, isMaintenance) {
      state.isMaintenance = isMaintenance;
    },
    pushErrors(state, errors) {
      state.errors.push(errors);
      if ((state.errors.length > 3) || (
        Vuetify.framework.breakpoint.smAndDown
        &&
        (state.errors.length > 1)
      )) {
        state.errors.shift();
      }
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  actions: {
    loadAdmin({ commit, state }) {
      AuthApi.auth().then(response => {
        // Load admin details
        commit('setAdmin', response.data.data);

        // Load settings
        if (response.data.data.settings) {
          commit('setDarkMode', response.data.data.settings.dark_mode);
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          // commit('pushErrors', error.response.data.errors); // Uncomment to push "Unauthenticated." errors.
        }
        if (error.response.status == 503) {
          // Switch into maintenance mode
          if (!state.isMaintenance) {
            commit('setMaintenance', true);
            window.location.reload();
          }
        } else {
          localStorage.clear();
        }
      });
    },
    switchDarkMode({ commit }, isDarkMode) {
      SettingsApi.update({
        settings: {'dark_mode': isDarkMode},
      });
      commit('setDarkMode', isDarkMode);
    },
    switchReverseMessageOrder({ commit }, reverseMessageOrder) {
      // Store the last choice (reverse message order on\off) in the user settings
      SettingsApi.update({
        settings: {'reverse_message_order': reverseMessageOrder},
      });

      // Switch the reverse message order on\off
      commit('setReverseMessageOrder', reverseMessageOrder);
    },
    pushErrors({ commit }, errors) {
      commit('pushErrors', errors);
    },
    clearErrors({ commit, state }) {
      if (state.errors.length) {
        commit('clearErrors');
      }
    },
  },
});

export default store;
