<template>
  <v-expansion-panel
    class="default-panel default-content--text"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <v-expansion-panel-header>
      {{ label }}
      <slot name="label" />
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'BaseSidebarViewerPanel',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
