<template>
  <base-dialog-confirmation
    :value="value"
    @input="$emit('input', $event)"
    :prompt="prompt"
    :confirm-text="removeText"
    confirm-color="red"
    @confirm="confirm"
    @cancel="cancel"
  ></base-dialog-confirmation>
</template>

<script>
export default {
  name: 'BaseDialogDeleteConfirmation',

  inheritAttrs: false,

  props: {
    value: {},
    prompt: {
      type: String,
      default: "Do you really want to do this?",
    },
    removeText: {
      type: String,
      default: "Remove",
    },
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },

  methods: {

    confirm: function () {
      this.$emit('removed', this.value);
    },

    cancel: function () {
      this.$emit('input', null);
    },

  },
};
</script>
