<template>
  <div class="pb-8">
    <base-dialog-delete-confirmation
        v-model="removeCommentDialog"
        prompt="Do you really want to remove this comment?"
        @removed="removeComment()"
      ></base-dialog-delete-confirmation>

    <div class="max-width-800 mx-auto mb-10">
      <v-textarea
          v-model="newComment"
          label="New Comment"
          counter
          outlined
          auto-grow
          rows="4"
          class="mt-1"
      ></v-textarea>
      <v-btn
          color="blue"
          dark
          class="button-xs-100"
          @click="AddComment()"
      >Add New Comment
      </v-btn>
    </div>

    <div
        v-if="!commentsTotal && !loading"
        class="ma-4"
    >
      No comments found.
    </div>
    
    <v-timeline
        v-if="commentsTotal"
        dense
        class="comments px-4"
    >
      <v-timeline-item
          v-for="comment in comments"
          :key="comment.id"
          large
          left
      >
        <template v-slot:icon>
          <v-avatar
            :color="comment.user_id ? 'primary' : 'info'"
          >
          <span
              class="white--text"
          >
            {{ comment.avatar }}
          </span>
          </v-avatar>
        </template>
        <v-card class="elevation-2">
          <v-card-actions
              v-if="comment.user_id === currentUserId"
              class="float-sm-right mt-1"
          >
            <v-tooltip
                bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    elevation="1"
                    class="ma-1"
                    @click="editedComment(comment.id)"
                    v-bind="attrs"
                    v-on="on"
                    small
                    tile
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit comment</span>
            </v-tooltip>
            <v-tooltip
                bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    elevation="1"
                    class="ma-1"
                    @click="confirmRemoveComment(comment.id)"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    small
                    tile
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete comment</span>
            </v-tooltip>
          </v-card-actions>
          <v-card-title>
            <span class="mr-auto">{{ comment.user_name || comment.integration_name }}
              <span v-if="comment.integration_id" class="font-weight-regular subtitle-2">(integration)</span>
            </span>
            <span class="font-italic subtitle-2">{{ getRelativeTime(comment.created_at) }}</span>
            <span
                v-if="comment.created_at !== comment.updated_at"
                class="font-italic subtitle-2 ml-sm-3">Edited: {{ getRelativeTime(comment.updated_at) }}</span>
          </v-card-title>
          <v-card-text>
            <div
                v-if="editedCommentId === comment.id"
            >
              <v-textarea
                  v-model="editedCommentText"
                  label="Edit Comment"
                  counter
                  outlined
                  auto-grow
                  rows="2"
              ></v-textarea>
              <v-btn
                  color="gray"
                  class="mb-4 mr-4"
                  @click="cancelEditing()"
              >Cancel
              </v-btn>
              <v-btn
                  color="blue"
                  dark
                  class="mb-4"
                  @click="updateComment(comment.id, {'text': editedCommentText})"
              >Save
              </v-btn>
            </div>
            <div
                v-else
                :class="(($store.state.isDarkMode) ? 'white--text' : 'black--text')"
                class="text-break"
            >
              {{ comment.text }}
            </div>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <div
        v-if="loading"
        class="ma-4"
    >
      <v-progress-linear
        indeterminate
        color="primary"
      ></v-progress-linear>
    </div>

    <div
        v-intersect="infiniteScrolling"
    ></div>
  </div>
</template>

<script>
import {displayDate} from "@/helpers/DateDisplay";

export default {
  name: 'BaseComments',

  data: () => ({
    comments: [],
    commentsTotal: 0,
    page: 0,
    loading: false,
    newComment: '',
    editedCommentId: null,
    editedCommentText: '',
    currentUserId: null,
    removeCommentDialog: false,
    removeCommentId: null,
  }),

  created() {
    this.currentUserId = this.$store.state.user.id;
  },

  methods: {
    infiniteScrolling: function (/*entries, observer, isIntersecting*/) {
      setTimeout(() => {
        this.loadData();
      }, 500);
    },
    editedComment: function (commentId) {
      this.editedCommentId = commentId;
      let editedComment = this.comments.find(item => item.id === commentId);
      if (editedComment) {
        this.editedCommentText = editedComment.text;
      }
    },
    cancelEditing: function () {
      this.editedCommentId = null;
      this.editedCommentText = '';
    },
    confirmRemoveComment: function (commentId) {
      this.removeCommentDialog = true;
      this.removeCommentId = commentId;
    },
    getRelativeTime: function (date) {
      return displayDate(new Date(date));
    },
  }
};
</script>

<style scoped>
.max-width-800 {
  max-width: 800px;
}
.comments.v-timeline::before {
  width: 0;
}
</style>
