<template>
  <base-data-table
    :items="data"
    :options.sync="dataTableOptions"
    :server-items-length="dataTotal"
    :loading="dataLoading"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
    :items-per-page="itemPerPage"
  >
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
  </base-data-table>
</template>

<script>
export default {
  name: 'BaseDataTableRemote',

  inheritAttrs: false,

  props: {
    getData: {
      type: Function,
      default: null,
    },
    rowsPerPage: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    dataTableOptions: {},
    dataLoading: true,
    data: [],
    dataTotal: 0,
  }),

  computed: {
    itemPerPage() {
      if (this.rowsPerPage) return this.rowsPerPage;

      let perPage = 10;
      if (window.screen.height >= 1200) {
        perPage = 15;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        perPage = 5;
      }
      return perPage;
    }
  },

  watch: {
    dataTableOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },

  methods: {

    loadData: function () {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;
      if (page && itemsPerPage) {
        this.dataLoading = true;
        this.getData(page, itemsPerPage, sortBy, sortDesc).then(response => {
          this.data = response.data.data;
          this.dataTotal = response.data.meta.total;
          if ((page == 1) && !this.dataTotal) {
            this.dataTotal = this.data.length;
          }
          this.$emit('data-total', this.dataTotal)
          this.dataLoading = false;
        })
        .catch(error => {
          this.dataLoading = false;
          if (error.response.data && error.response.data.errors) {
            this.$store.dispatch('pushErrors', error.response.data.errors);
          }
        });
      }
    },

  },

};
</script>
