<template>
  <v-app-bar
    :color="!$store.state.isDarkMode ? 'blue' : 'darken-3'"
    class="darken-3"
    dark
    elevation="0"
    app
    dense
  >
    <v-toolbar-title></v-toolbar-title>

    <v-avatar
      v-if="$store.getters.authenticated"
      size="30"
      class="blue--text mr-3 text--darken-3"
    >
      <v-img
        src="../../assets/logo-white-30.png"
        max-width="30"
        max-height="30"
        contain
      ></v-img>
    </v-avatar>

    <base-main-nav-btn
      v-if="$store.getters.authenticated"
      to="/"
      icon="mdi-view-dashboard"
    >Dashboard</base-main-nav-btn>

    <v-menu
      v-if="$store.getters.authenticated"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          icon="mdi-domain"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-1" small>mdi-domain</v-icon>
          Accounts
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-domain</v-icon>
          Accounts
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          to="/accounts"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Accounts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-domain</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/users"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-group</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          to="/admins"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Admins</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-shield-account</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="$store.getters.authenticated"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          icon="mdi-robot-angry"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-1" small>mdi-robot-angry</v-icon>
          Bots
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-robot-angry</v-icon>
          Bots
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          to="/bot-servers"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Servers</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-server</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/bots"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Instances</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-robot</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/bot-queue"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Queue</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-format-list-checks</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/bot-errors"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Errors</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-bug</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/bot-view"
          link
        >
          <v-list-item-content>
            <v-list-item-title>View</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-desktop-classic</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/bot-grid"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Grid</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-view-grid</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <base-main-nav-btn
      v-if="$store.getters.authenticated"
      to="/workflows"
    >
      <v-icon class="mr-1" small>mdi-cogs</v-icon>
      Workflows
    </base-main-nav-btn>

    <v-menu
      v-if="$store.getters.authenticated"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          icon="mdi-account-box-multiple"
          v-bind="attrs"
          v-on="on"
        >
          Contacts
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-account-box-multiple</v-icon>
          Contacts
          <base-info-icon
            label="Manage and organize your contact data."
          />
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          to="/contacts"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Contacts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-contacts</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/companies"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Companies</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-briefcase</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/contact-lists"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Lists</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-table-account</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/contacts-import"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Import</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-cloud-upload</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="$store.getters.authenticated && !truncateMenu"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          icon="mdi-bullseye-arrow"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-1" small>mdi-bullseye-arrow</v-icon>
          CRM
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-bullseye-arrow</v-icon>
          CRM
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          to="/task-boards"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Tasks</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-bullseye-arrow</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/notes"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Notes</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-note</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/tags"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Tags</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-tag</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="$store.getters.authenticated"
      v-model="menuLinkedin"
      bottom
      left
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-1" small>mdi-linkedin</v-icon>
          LinkedIn
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-linkedin</v-icon>
          LinkedIn
          <base-info-icon
            label="Manage your LinkedIn accounts and the related data."
          />
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          to="/linkedin"
          @click="closeLinkedinMenu"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Accounts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-box-multiple</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          to="/linkedin-profiles"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Profiles</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-contacts</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          to="/linkedin-conversations"
          @click="closeLinkedinMenu"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Conversations</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-forum</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          to="/linkedin-searches"
          @click="closeLinkedinMenu"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Searches</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-search</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          to="/linkedin-connections"
          @click="closeLinkedinMenu"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Connections</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-multiple</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-group
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Invitations</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            to="/linkedin-invitations-received"
            @click="closeLinkedinMenu"
            link
            class="pl-6"
          >
            <v-list-item-content>
              <v-list-item-title>Received</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-account-multiple-plus</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            to="/linkedin-invitations-sent"
            @click="closeLinkedinMenu"
            link
            class="pl-6"
          >
            <v-list-item-content>
              <v-list-item-title>Sent</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-account-multiple-plus-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-item
          to="/linkedin-suggestions"
          @click="closeLinkedinMenu"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Suggestions</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-question</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/linkedin-views"
          @click="closeLinkedinMenu"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Views</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-target-account</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          to="/linkedin-groups"
          @click="closeLinkedinMenu"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Groups</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-account-group</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-group
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>LinkedIn Company Page</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            @click="closeLinkedinMenu"
            link
            class="pl-6"
            :disabled="true"
          >
            <v-list-item-content>
              <v-list-item-title>Posts</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-note-text</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            @click="closeLinkedinMenu"
            link
            class="pl-6"
            :disabled="true"
          >
            <v-list-item-content>
              <v-list-item-title>Invitations Sent</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>mdi-briefcase-plus</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-menu>

    <v-menu
      v-if="$store.getters.authenticated && !truncateMenu"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-1" small>mdi-wrench</v-icon>
          Integrations
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-wrench</v-icon>
          Integrations
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          to="/integrations"
          link
        >
          <v-list-item-content>
            <v-list-item-title>API Integrations</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-api</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/webhooks"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Webhooks</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-webhook</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="$store.getters.authenticated && !truncateMenuL1"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          icon="mdi-chart-box"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-1" small>mdi-chart-box</v-icon>
          Reports
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-subheader>
          <v-icon small class="mr-1">mdi-chart-box</v-icon>
          Reports
          <base-info-icon
            label="Track and measure performance of your account."
          />
        </v-subheader>

        <v-divider></v-divider>

        <v-list-item
          to="/overview"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-calendar</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/reports"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Charts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-chart-areaspline</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="$store.getters.authenticated && truncateMenu"
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <base-main-nav-btn
          icon="mdi-table"
          v-bind="attrs"
          v-on="on"
        >
          <span v-if="!truncateMenuL3">More</span>
          <v-icon>mdi-menu-down</v-icon>
        </base-main-nav-btn>
      </template>

      <v-list
        dense
      >
        <v-list-item
          to="/task-boards"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Tasks</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-bullseye-arrow</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/notes"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Notes</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-note</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/tags"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Tags</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-tag</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader>
          <v-icon small class="mr-1">mdi-chart-box</v-icon>
          Reports
          <base-info-icon
            label="Track and measure performance of your account."
          />
        </v-subheader>

        <v-list-item
          to="/overview"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-calendar</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/reports"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Charts</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-chart-areaspline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader>
          <v-icon small class="mr-1">mdi-wrench</v-icon>
          Integrations
        </v-subheader>

        <v-list-item
          to="/integrations"
          link
        >
          <v-list-item-content>
            <v-list-item-title>API Integrations</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-api</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/webhooks"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Webhooks</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-webhook</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      v-if="$store.getters.authenticated"
      to="/sarah-walker"
      link
      icon
      class="mx-1"
      small
    >
      <v-icon>mdi-crown</v-icon>
    </v-btn>

    <v-spacer></v-spacer>

    <main-nav-notifications
      v-if="$store.getters.authenticated"
    ></main-nav-notifications>

    <v-btn
      v-if="$store.getters.authenticated"
      to="/settings"
      link
      icon
      class="mx-1"
      small
    >
      <v-icon small>mdi-account-circle</v-icon>
    </v-btn>

    <div
      v-if="$store.getters.authenticated"
    >
      <v-tooltip v-if="$darkMode" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', false)">
            <v-icon small color="yellow" class="mr-1">mdi-moon-waxing-crescent</v-icon>
          </v-btn>
        </template>
        <span>Disable dark mode</span>
      </v-tooltip>
      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', true)">
            <v-icon small color="yellow">mdi-white-balance-sunny</v-icon>
          </v-btn>
        </template>
        <span>Enable dark mode</span>
      </v-tooltip>
    </div>

    <v-divider
      v-if="$store.getters.authenticated"
      v-show="!this.isAuthView"
      vertical
      class="ml-2"
    ></v-divider>

    <v-btn
      class="main-nav-btn mx-2"
      small
      text v-if="!this.$store.getters.authenticated"
      :to="{name: 'Login'}"
    >
      <v-icon class="mr-1" small>mdi-login</v-icon>
      Login
    </v-btn>
    <v-btn
      class="mx-2"
      icon
      small
      v-if="this.$store.getters.authenticated"
      @click="logoutConfirmation = true"
    >
      <v-icon small>mdi-logout</v-icon>
    </v-btn>
    <base-dialog-confirmation
      v-model="logoutConfirmation"
      prompt="Do you really want to logout?"
      confirm-text="Logout"
      confirm-color="red"
      @confirm="logout"
      @cancel="logoutConfirmation = false"
    ></base-dialog-confirmation>
  </v-app-bar>
</template>

<script>
import MainNavNotifications from '@/components/layout/MainNavNotifications.vue';

export default {
  name: 'MainNavV2',

  components: {
    MainNavNotifications,
  },

  data() {
    return {
      logoutConfirmation: false,
      menuLinkedin: false,
      truncateMenu: false,
      truncateMenuL1: false,
      truncateMenuL2: false,
      truncateMenuL3: false,
    };
  },

  computed: {
    isAuthView() {
      // Check if on auth pages (login, sign up, password reset, etc)
      if (this.$route && this.$route.meta) {
        return this.$route.meta.public;
      }
      return false;
    },
  },

  created() {
    this.resizeScreen();
    window.addEventListener("resize", this.resizeScreen);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeScreen);
  },

  methods: {

    logout: function () {
      this.$emit('logout');
    },

    resizeScreen: function () {
      this.truncateMenu = window.innerWidth < 1600;
      this.truncateMenuL1 = window.innerWidth < 1300;
      this.truncateMenuL2 = window.innerWidth < 1200;
      this.truncateMenuL3 = window.innerWidth < 1050;
    },

    closeLinkedinMenu: function () {
      this.menuLinkedin = false;
    },

  },
};
</script>
