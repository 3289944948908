import Api from "@/api/Api";

export default {
  getNotifications(page, pageSize) {
    var params = {};
    if (page) params['page'] = page;
    if (pageSize) params['paginate'] = pageSize;
    params = (new URLSearchParams(params)).toString();
    if (params.length) params = '?' + params;
    return Api().get('/notifications' + params);
  },
};
