<template>
  <v-menu
    v-model="showMenu"
    bottom
    :close-on-content-click="false"
  >
    <template v-slot:activator="menu">
      <v-text-field
        @click="showMenu = true"
        v-bind="{ ...$attrs, ...menu.attrs }"
        v-on="{ ...$listeners, ...menu.on }"
        :value="value"
        label="Date and Time"
        prepend-icon="mdi-calendar"
        readonly
      ></v-text-field>
    </template>

    <v-card
      class="datetime-picker"
    >
      <div>
        <v-date-picker
          v-model="date"
          :min="minDate"
          @change="changeDateTime"
          first-day-of-week="1"
          flat
          landscape
        ></v-date-picker>
      </div>

      <div>
        <v-time-picker
          v-model="time"
          :min="minTime"
          @change="changeDateTime"
          format="24hr"
          flat
          landscape
        ></v-time-picker>
      </div>

      <div
        class="d-flex"
      >
        <base-sidebar-btn
          @click="confirmDateTime"
          label="Confirm"
          icon="mdi-check"
          :btn-class="!$store.state.isDarkMode ? 'blue darken-2' : 'pink lighten-2'"
          :icon-color="!$store.state.isDarkMode ? 'white' : 'white'"
          style="width: 170px;"
        ></base-sidebar-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseDatetimePicker',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
    onlyFuture: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    lastValue: null,
    date: '',
    time: '',
    showMenu: false,
  }),

  computed: {
    minDate() {
      return this.onlyFuture ? (new Date()).toISOString().split('T')[0] : '';
    },
    minTime() {
      return (this.onlyFuture && this.date === this.minDate) ? (new Date()).toTimeString().split(' ')[0] : '';
    },
  },

  watch: {
    value() {
      this.loadDateTime();
    },
  },

  mounted() {
    this.loadDateTime();
  },

  methods: {

    loadDateTime: function () {
      if (this.lastValue && (this.lastValue == this.value)) return;

      if (this.value) {
        this.date = (new Date(Date.parse(this.value))).toISOString().split('T')[0];
        this.time = (new Date(Date.parse(this.value))).toTimeString().split(' ')[0];
      } else {
        this.date = this.formatDate(new Date());
        this.time = '00:00';
      }
      this.changeDateTime();
    },

    changeDateTime: function () {
      var val = this.date + ' ' + this.time;
      if (this.value != val) {
        this.lastValue = val;
        this.$emit('input', val);
      }
    },

    formatDate: function (date) {
      return date.getFullYear() + '-' + ('0'+(date.getMonth() + 1)).slice(-2) + '-' + ('0'+(date.getDate())).slice(-2);
    },

    confirmDateTime: function () {
      this.showMenu = false;
      this.changeDateTime();
    }

  }
};
</script>

<style>
.datetime-picker .v-picker__title {
  border-radius: 0 !important;
}
</style>
