<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    :width="width"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <template v-slot:prepend>
      <base-sidebar-viewer-btn
        @click="$emit('input', false)"
        icon="mdi-close"
        label="Close"
        btn-class="mt-2 background"
        icon-color="grey"
        absolute
        right
      ></base-sidebar-viewer-btn>
    </template>
    <slot />
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'BaseSidebar',

  inheritAttrs: false,

  props: {
    value: {},
    width: {
      type: [Number, String],
      default: 350,
    },
  },
};
</script>
