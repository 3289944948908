<template>
  <base-dialog-confirmation
      :value="value"
      :prompt="'This ' + objectName + ' is used in the following objects:'"
      confirm-text="Remove"
      confirm-color="red"
      cancel-text="Cancel"
      @confirm="confirm"
      @cancel="cancel"
  >
    <v-list class="pt-0 px-2">
      <v-list-item
          v-for="filter in contactFilters"
          :key="filter.id"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ getFilterName(filter) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ getFilterType(filter) }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-chip><a :href="getFilterLink(filter)" target="_blank" class="text-decoration-none color-inherit">show</a>
          </v-chip>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <p class="title px-6">Do you still want to remove this {{ objectName }}?</p>
  </base-dialog-confirmation>
</template>

<script>
export default {
  name: 'BaseObjectIsUsedConfirmation',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    accountId: {
      type: Number,
      default: null,
    },
    objectName: {
      type: String,
      default: 'object',
    },
    contactFilters: {
      type: Array,
      default: () => {[]},
    },
  },

  methods: {
    confirm: function () {
      this.$emit('confirm');
    },
    cancel: function () {
      this.$emit('cancel');
    },
    getFilterName: function (filter) {
      return this.isFilterWorkflow(filter)
          ? (filter.workflow ? filter.workflow.name : filter.workflow_action.workflow.name)
          : filter.name;
    },
    getFilterType: function (filter) {
      return this.isFilterWorkflow(filter) ? 'Workflow' : 'Contact Filter';
    },
    getFilterLink: function (filter) {
      return this.isFilterWorkflow(filter)
          ? '/account/' + this.accountId + '/workflow/' + (filter.workflow ? filter.workflow.id : filter.workflow_action.workflow_id)
          : '/account/' + this.accountId + '/contact-filter/' + filter.id;
    },
    isFilterWorkflow: function (filter) {
      return filter.workflow || filter.workflow_action;
    },
  },
};
</script>
