import axios from "axios";
import store from "@/store";

let CoreApi = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

let Api = function() {
  if (store.state.token) {
    CoreApi.defaults.headers.common["Authorization"] = 'Bearer ' + store.state.token;
  }

  return CoreApi;
};

export default Api;
