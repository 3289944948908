<template>
  <div class="ma-0 pa-0">
    <v-card
      class="pa-3 flowy-drag-handle"
      elevation="1"
    >
      <div>
        <div class="row items-center no-wrap">
          <div class="col">
            <div class="text-h6">{{ title }}</div>
          </div>

          <div class="col-auto">
            <v-icon>mdi-drag</v-icon>
          </div>
        </div>
      </div>
    </v-card>
	</div>
</template>

<script>
export default {
  name: 'WorkflowActionBlock',

  props: {
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    remove: {
      type: Function,
      required: false,
    },
  },

  data () {
    return {

    };
  },

  mounted () {

  },

  destroyed () {

  },

  methods: {

  },
};
</script>
