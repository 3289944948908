<template>
  <v-card
    elevation="2"
    class="d-flex align-center px-4 py-1 default-content--text"
    color="default-panel"
    height="64"
  >
    <v-tooltip
      v-if="back"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :to="back"
          class="mr-1"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>mdi-backspace</v-icon>
        </v-btn>
      </template>
      <span>Go back</span>
    </v-tooltip>
    <v-toolbar-title>{{ title }}</v-toolbar-title>

    <base-view-header-btn
      v-if="hasTour && $listeners['tour-view']"
      label="About the page"
      icon="mdi-help-box"
      @click="$emit('tour-view')"
      tile
      elevation="0"
      :color="($store.state.isDarkMode) ? '' : 'white'"
      :icon-color="($store.state.isDarkMode) ? 'primary darken-1' : 'primary'"
    ></base-view-header-btn>

    <slot
      name="title"
    ></slot>

    <v-spacer></v-spacer>

    <div>
      <slot
        name="filters"
      ></slot>
    </div>

    <div>
      <slot
        name="actions"
      ></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'BaseViewHeader',

  props: {
    title: {
      type: String,
      default: '',
    },
    hasTour: {
      type: [Boolean, Function],
      default: false,
    },
    back: {
      type: String,
      default: '',
    },
  },
};
</script>
