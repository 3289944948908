<template>
  <v-alert
      border="right"
      colored-border
      type="info"
      elevation="2"
      class="message-alert"
      tile
      dense
  >{{ message }}
  </v-alert>
</template>

<script>
export default {
  name: 'BaseMessageAlerts',

  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.message-alert {
  position: fixed;
  top: 100px;
  left: calc(50% - 300px);
  width: 600px;
}
</style>
