<template>
  <base-view-header-btn
    :label="label"
    :icon="icon"
    :color="!$store.state.isDarkMode ? color : color + ' darken-3'"
    dark
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  ></base-view-header-btn>
</template>

<script>
export default {
  name: 'BaseViewHeaderPrimaryBtn',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: 'Add new',
    },
    icon: {
      type: String,
      default: 'mdi-plus',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>
