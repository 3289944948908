<template>
  <div class="ma-0 pa-0">
    <div>
      <v-card
        class="pa-3 text-center"
        :color="(this.node.data.type == 'delete') ? 'white' : 'background'"
        :style="{ opacity: ((this.node.hidden) ? 0 : 1) }"
        width="275"
        :disabled="(this.node.data.type == 'delete')"
        :height="(this.node.data.type == 'delete') ? 100 : 75"
        :elevation="(this.node.data.type == 'delete') ? 1 : 0"
      >
        <v-card-text>
          <v-icon
            v-if="this.node.data.type == 'end'"
            class="mb-7"
          >mdi-flag-checkered</v-icon>
          <div v-html="description"></div>
          <v-btn
            v-if="(this.node.data.type == 'end')"
            elevation="1"
            fab
            x-small
            class="mt-7"
            @click="newNode"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >mdi-plus</v-icon>
              </template>
              <span>Add Action</span>
            </v-tooltip>
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
	</div>
</template>

<script>
export default {
  name: 'WorkflowActionInfoNode',

  props: {
    remove: {
      type: Function,
      required: true,
    },
    node: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },

  data () {
    return {

    };
  },

  mounted () {

  },

  destroyed () {

  },

  methods: {

    newNode () {
      this.node.addNew = true;
      this.remove();
    },

  },
};
</script>
