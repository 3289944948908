<template>
  <v-btn
      elevation="1"
      tile
      :class="btnClass"
      :color="iconColor"
      text
      v-bind="{ ...$attrs }"
      v-on="{ ...$listeners }"
  >
    <v-icon
        small
        :color="iconColor"
        :class="iconClass"
        class="mr-1"
    >{{ icon }}</v-icon>
    <span>{{ label }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseSidebarBtn',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: 'Cancel',
    },
    icon: {
      type: String,
      default: '',
    },
    btnClass: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
};
</script>
