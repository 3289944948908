<template>
  <v-menu
      bottom
      left
      :close-on-content-click="false"
      :value="closeMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          small
          elevation="1"
          class="mx-1"
          :class="!$store.state.isDarkMode ? 'background' : 'background' + ' lighten-1'"
          @click="closeMenu = true"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon
            small
        >{{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
      >
        <v-list-item-title>
          <slot></slot>
        </v-list-item-title>
        <v-list-item-action class="my-0 align-center">
          <base-sidebar-viewer-btn
            @click="closeMenu = false"
            icon="mdi-close"
            label="Close"
            btn-class="background"
            icon-color="grey"
            absolute
            right
          ></base-sidebar-viewer-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseViewHeaderMobileBtn',

  props: {
    icon: {
      type: String,
      default: 'mdi-magnify',
    },
  },

  data() {
    return {
      closeMenu: false,
    }
  },
};
</script>
