<template>
  <base-dialog-prompt
    :value="value"
    @input="$emit('input', $event)"
    :max-width="!onlyBgColor ? '600px' : '400px'"
  >
    <template v-slot:title>
      <p class="text-center w-100">{{ prompt }}</p>
    </template>
    <template v-slot:content>
      <v-row
          class="color-picker mx-0"
      >
        <v-col
            cols="12"
            :sm="!onlyBgColor ? 6 : 12"
        >
          <p
              v-if="!onlyBgColor"
              class="text-center subtitle-1"
          >Background color:</p>
          <v-color-picker
              class="mx-auto"
              hide-inputs
              :value="bgColor"
              @input="$emit('bgColor', $event)"
              :swatches="bgSwatches"
              show-swatches
          ></v-color-picker>
        </v-col>
        <v-col
            v-if="!onlyBgColor"
            cols="12"
            sm="6"
        >
          <p class="text-center subtitle-1">Text color:</p>
          <v-color-picker
              class="mx-auto"
              hide-inputs
              :value="textColor"
              @input="$emit('textColor', $event)"
              :swatches="textSwatches"
              show-swatches
          ></v-color-picker>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn
        :color="resetColor"
        dark
        @click.native="resetSettings()"
      >{{ resetText }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :color="cancelColor"
        @click.native="cancel()"
      >{{ cancelText }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :color="confirmColor"
        dark
        @click.native="confirm()"
      >{{ confirmText }}</v-btn>
    </template>
  </base-dialog-prompt>
</template>

<script>
export default {
  name: 'BaseDialogColorPicker',

  inheritAttrs: false,

  props: {
    value: {},
    prompt: {
      type: String,
      default: "Do you really want to proceed?",
    },
    confirmText: {
      type: String,
      default: "Select",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    confirmColor: {
      type: String,
      default: "primary",
    },
    cancelColor: {
      type: String,
      default: "",
    },
    resetText: {
      type: String,
      default: "Reset",
    },
    resetColor: {
      type: String,
      default: "red",
    },
    bgColor: {
      type: String,
      default: '#FFFFFF',
    },
    textColor: {
      type: String,
      default: '#000000',
    },
    onlyBgColor: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      bgSwatches: [
        ['#FF0000', '#E91E63', '#9C27B0'],
        ['#FFFF00', '#FFC107', '#FF5722'],
        ['#00FF00', '#00AA00', '#00695C'],
        ['#00FFFF', '#00BCD4', '#009688'],
      ],
      textSwatches: [
        ['#212121',],
        ['#FAFAFA',],
      ],
    }
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },

  methods: {

    confirm: function () {
      this.$emit('confirm');
    },

    cancel: function () {
      this.$emit('cancel');
    },

    resetSettings: function () {
      this.$emit('reset');
    },
  },
};
</script>

<style>
.color-picker .v-color-picker__swatch .v-color-picker__color {
  border: 1px solid #080808;
}
.theme--dark .color-picker .v-color-picker__swatch .v-color-picker__color {
  border-color: #f5f5f5;
}
</style>
