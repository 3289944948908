<template>
  <v-data-table
    class="default-panel default-content--text"
    :class="[{ 'row-pointer': $listeners['click:row'] }, `elevation-${elevation}`]"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'BaseDataTable',

  inheritAttrs: false,

  props: {
    elevation: {
      type: [String, Number],
      default: 2,
    },
  },
};
</script>
