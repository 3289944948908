<template>
  <v-tooltip
    v-if="value.length > limit"
    bottom
  >
    <template v-slot:activator="tooltip">
      <span
        v-bind="{ ...$attrs, ...tooltip.attrs }"
        v-on="{ ...$listeners, ...tooltip.on }"
      >
        {{ value.length > limit ? (value.slice(0, (limit-3)) + '...') : value }}
      </span>
    </template>
    <span>{{ value }}</span>
  </v-tooltip>
  <span
    v-else
    v-bind="{ ...$attrs }"
    v-on="{ ...$listeners }"
  >
    {{ value }}
  </span>
</template>

<script>
export default {
  name: 'BaseTruncatedString',

  props: {
    value: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 25,
    },
  },

};
</script>
