<template>
  <base-sidebar
    :value="value"
    @input="$emit('input', $event)"
    fixed
    right
    temporary
    width="400"
    class="pa-0"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <div
      class="d-inline-flex"
    >
      <base-picture-avatar
        v-if="picture || defaultPicture"
        :src="(picture) ? picture : ''"
        :src-default="defaultPicture"
        class="my-5 ml-5 mr-2"
        width="50"
        height="50"
      ></base-picture-avatar>

      <div>
        <v-card-title
          class="text-h6"
          style="word-break: normal;"
        >{{ title }}</v-card-title>

        <v-card-subtitle
          class="text-subtitle-1"
        >{{ subtitle }}</v-card-subtitle>
      </div>
    </div>

    <v-divider></v-divider>

    <v-expansion-panels
      v-model="panels"
      multiple
      tile
      accordion
    >
      <slot />
    </v-expansion-panels>

    <v-card-actions
      class="pa-5"
    >
      <base-sidebar-btn
        v-if="view"
        :to="view"
        :label="viewLabel"
        :icon="viewIcon"
        :btn-class="!$store.state.isDarkMode ? 'mr-4 blue' : 'mr-4 blue darken-3'"
        icon-color="white"
      ></base-sidebar-btn>
      <slot name="actions" />
      <base-sidebar-btn
        @click="$emit('input', false)"
        icon="mdi-close"
        label="Close"
        btn-class="background"
        icon-color="grey"
      ></base-sidebar-btn>
    </v-card-actions>
  </base-sidebar>
</template>

<script>
export default {
  name: 'BaseSidebarViewer',

  inheritAttrs: false,

  props: {
    value: {},
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    picture: {
      type: String,
      default: '',
    },
    defaultPicture: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: null,
    },
    viewLabel: {
      type: String,
      default: 'View details',
    },
    viewIcon: {
      type: String,
      default: 'mdi-open-in-new',
    },
  },

  data: () => ({
    panels: [
      0, // expand the first panel by default
    ],
  })
};
</script>
