<template>
  <v-btn
    elevation="2"
    fab
    tile
    fixed
    class="ml-3"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <v-tooltip top>
      <template v-slot:activator="tooltip">
        <v-icon
          :color="iconColor"
          :class="iconClass"
          v-bind="tooltip.attrs"
          v-on="tooltip.on"
        >{{ icon }}</v-icon>
      </template>
      <span>{{ label }}</span>
    </v-tooltip>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseEditorBtn',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>
